import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import React from "react";

function App() {
  return (
    <div className="pointer-events-auto">
      <Parallax pages={8}>
        <>
          <ParallaxLayer id="home" offset={0} speed={0.2}>
            <img
              className="w-full h-full object-cover"
              alt="luganoImage"
              src={"adesso2.jpg"}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.3}>
            <div
              className="flex flex-col justify-center items-center w-[90%] md:w-fit gap-4 bg-white rounded-xl opacity-70 py-4"
              style={{
                position: "absolute",
                top: "35%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <p className="w-full text-5xl font-bold text-center">
                Cultural Fit Event
              </p>
              <p className="w-full text-xl font-bold text-center">
                6<sup>th</sup>-7<sup>th</sup> December 2023
              </p>
              <div className="flex flex-col md:flex-row justify-center items-center">
                <img
                  className="w-[300px]"
                  alt="adessoLogo"
                  src={"adessoLogo.svg"}
                />
                <img
                  className="w-[300px]"
                  alt="swiscomLogo"
                  src={"swisscomLogo.png"}
                />
              </div>
            </div>
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer id="adesso" offset={1} speed={0.5}>
            <img
              className="leftTextContainer w-[300px] opacity-10 md:opacity-100"
              alt="adessoLogo"
              src={"adessoLogo.svg"}
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer offset={1} speed={1}>
            <div className="leftTextContainer flex flex-col w-[500px] md:w-[800px] justify-center items-center gap-2 z-10">
              <p className="w-1/2 text-2xl md:text-3xl font-bold text-center md:text-start">
                Selfie Challange 🤳🏽
              </p>
              <div className="w-1/2 text-center md:text-start text-xl ">
                Visit Our office and take a selfie
                <br />
                Wednesday 6<sup>th</sup> in the morning (before we start the
                activity) or on Thursday the 7<sup>th</sup>!
                <br />
                Send it to camilla.tombetti@adesso.ch
                <br />
                Most Funny Selfie wins a Prize
                <br />
                <br />
              </div>
              <p className="w-1/2 text-xl text-start">
                <span className="font-bold text-blue">adesso Lugano</span>
                <br />
                Corso Enrico Pestalozzi 9
                <br />
                CH-6900 Lugano
                <br />3<sup>rd</sup> and 6<sup>th</sup> floor
              </p>
            </div>
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer id="activity" offset={2} speed={0.5}>
            <img
              className="w-[300px] opacity-10 md:opacity-100"
              alt="agenda"
              src={"agenda.png"}
              style={{
                position: "absolute",
                top: "50%",
                left: "70%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <div className="rightTextContainer flex flex-col w-[450px] md:w-[800px] justify-center items-center gap-2 z-10">
              <p className="w-1/2 text-xl md:text-2xl  text-start">
                <span className="text-blue font-bold">
                  Meet, Play and Growth
                </span>
                <span className="inline-block text-center md:text-start">
                  with Lucia Berdini{" "}
                  <a
                    className="text-blue font-bold underline"
                    href="https://peplo.me"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Peplo.me
                  </a>
                </span>
              </p>
              <table className="table-fixed font-bold">
                <thead>
                  <tr className="text-blue">
                    <th className="w-[250px] md:w-[400px]">Agenda</th>
                    <th className="w-[100px] md:w-[200px]">Hour</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Team building - part I</td>
                    <td className="text-center">3 - 4.30pm</td>
                  </tr>
                  <tr>
                    <td className="italic">Coffee break</td>
                    <td className="text-center">4.30 - 5pm</td>
                  </tr>
                  <tr>
                    <td>Team building - part II</td>
                    <td className="text-center">5 - 7.30pm</td>
                  </tr>
                  <tr>
                    <td className="italic">
                      Speech <br />
                      -John de Keijzer
                      <br />
                      -Hansjörg Süess
                    </td>
                    <td className="text-center">7.30-7.50pm</td>
                  </tr>
                  <tr>
                    <td>Way to the restaurant</td>
                    <td className="text-center">7.50pm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer
            id="hotel"
            offset={3}
            sticky={{ start: 3, end: 4 }}
            speed={0.5}
          >
            <img
              className=" w-[300px] opacity-10 md:opacity-100 "
              alt="hotel"
              src={"hotel.png"}
              style={{
                position: "absolute",
                top: "50%",
                left: "30%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </ParallaxLayer>
          <ParallaxLayer offset={3} speed={1}>
            <div className="leftTextContainer flex flex-col justify-center items-center gap-2">
              <p className="w-full md:w-1/2 text-xl md:text-2xl font-bold text-start">
                <span className="text-blue">Hotel Acquarello</span>
                <span className="inline-block">Piazza Cioccaro 9</span>
                <span className="inline-block">CH-6900 Lugano</span>
              </p>
            </div>
          </ParallaxLayer>
          <ParallaxLayer offset={4} speed={1.5}>
            <div className="leftTextContainer flex flex-col justify-center items-center gap-2">
              <p className="w-full md:w-1/2 text-xl md:text-2xl font-bold text-start">
                <span className="text-blue">Hotel Pestalozzi</span>
                <span className="inline-block">Piazza Indipendenza 9</span>
                <span className="inline-block">CH-6901 Lugano</span>
              </p>
            </div>
          </ParallaxLayer>
        </>

        <>
          <ParallaxLayer
            id="menu"
            offset={5}
            sticky={{ start: 5, end: 7 }}
            speed={0.2}
          >
            <div
              className="flex flex-col gap-4 items-center"
              style={{
                position: "absolute",
                top: "50%",
                left: "25%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img
                className="w-[200px] opacity-10 md:opacity-100"
                alt="menu"
                src={"menu.png"}
              />
              <div className="flex justify-start items-center gap-2">
                <img
                  className="w-[25px] opacity-10 md:opacity-100"
                  alt="instagram"
                  src={"ig.png"}
                />
                <span className="font-bold opacity-20 md:opacity-100 text-blue">
                  @
                  <a
                    className="underline "
                    href="https://www.instagram.com/lafermatalugano"
                    target="_blank"
                    rel="noreferrer"
                  >
                    lafermatalugano
                  </a>
                </span>
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer offset={5} speed={1}>
            <div className="leftTextContainer flex flex-col w-[300px] md:w-[800px] justify-center items-center gap-4 z-10">
              <div>
                <h3 className="text-2xl md:text-3xl font-bold text-center text-blue">
                  STARTERS TO SHARE
                </h3>
                <ul className="text-center">
                  <li>
                    <strong>TAKOYAKI</strong>
                    <br />
                    Grilled octopus spheres, chives, pickled ginger, japanese
                    mayo, sweet soy, katsuobushi, nori seeweed
                  </li>
                  <li>
                    <strong>JALAPENO POPPERS</strong>
                    <br />
                    Jalapeños stuffed with cheese, deep sauce
                  </li>
                  <li>
                    <strong>BOLINHAS DE BACALHAU</strong>
                    <br />
                    Quenelles of cod and potatoes, special sauce
                  </li>
                  <li>
                    <strong>TACAOS BIRRIA</strong>
                    <br /> Corn tortillas, beef cooked at low temperature, queso
                    Oaxaca, tomatillo and avocado sauce, onion, cilantro
                  </li>
                  <li>
                    <strong>TACOS BLT DE GALLO</strong>
                    <br /> Flour tortillas, fried chicken with panko, sour
                    cream, pico de gallo, red cabbage, guacamole, lettuce,
                    bacon, mango chutney
                  </li>

                  <li>
                    <strong>NACHOS FERMATA</strong>
                    <br /> Corn chips, chili meat, guacamole, sour cream, queso
                    Chihuahua, pickled jalapeños, pico de gallo
                  </li>

                  <li>
                    <br />
                    <span className="text-xl underline">
                      Vegan & Vegetarian
                    </span>
                  </li>

                  <li>
                    <strong>ONION RINGS</strong>
                    <br /> Breaded onion rings, BBQ sauce
                  </li>
                  <li>
                    <strong>GUACA CHIPS</strong>
                    <br /> Avocado cream, pico de gallo, corn chips, pickled
                    jalapeños
                  </li>
                  <li>
                    <strong>TACOS BLV</strong>
                    <br /> Flour tortillas, vegan tenders, sour cream, pico de
                    gallo, red cabbage, guacamole, lettuce, vegetable bacon,
                    mango chutney
                  </li>
                  <li>
                    <strong>NACHOS WITH SOYA CHILI</strong>
                  </li>

                  <li>
                    <br />
                    <span className="text-xl underline">
                      Gluten and lactose free variation
                    </span>
                  </li>
                  <li>
                    <strong>TUNA CEVICHE</strong>
                  </li>
                  <li>
                    <strong>TACO BIRRIA</strong>
                  </li>
                  <li>
                    <strong>NACHOS FERMATA</strong>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl md:text-3xl font-bold text-center text-blue">
                  MAIN
                </h3>
                <ul className="text-center">
                  <li>
                    <strong>FERMATA BURGER</strong>
                    <br />
                    Beef, lettuce, tomato, pickles, caramelized onion, home
                    sauce
                  </li>
                  <li>
                    <strong>FRENCH FIRES</strong>
                    <br />+ Gluten and lactose free variation
                  </li>
                  <li>
                    <br />
                    <span className="text-xl underline">
                      Vegan & Vegetarian
                    </span>
                  </li>
                  <li>
                    <strong>EVERGREEN BURGER</strong> <br />
                    <br /> Veggie patty, lettuce, tomato, pickles, caramelized
                    onion, home sauce
                  </li>
                  <li>
                    <strong>FRENCH FIRES</strong>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl md:text-3xl font-bold text-center text-blue">
                  DESSERT
                </h3>
                <br />
                <ul className="text-center">
                  <li>
                    <strong>PANNA COTTA</strong>
                  </li>
                  <li>
                    <strong>COFFEE</strong>
                  </li>
                </ul>
              </div>
            </div>
          </ParallaxLayer>
        </>
      </Parallax>
    </div>
  );
}

export default App;
